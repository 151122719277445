import styled, { css } from 'styled-components'
import { Card, Tooltip } from 'antd'
import { color, rgbaColor, borderRadius, padding, fontSize } from '@libs/theme/mixins'
import { ContainerStyled } from '@libs/components'

const SubmitButtonContainer = styled(Tooltip)`
	width: 100%;

	> div,
	button {
		width: 100%;
	}
`

const OrderType = styled(Card)<{ selected: boolean; $smallSize?: boolean }>`
	.ant-card-body {
		transition: all 0.3s;
		cursor: pointer;
		position: relative;
		padding: 0;
		border-width: 4px;
		border-radius: ${borderRadius('md')};

		.ant-card-head-title {
			overflow: visible;
		}

		${ContainerStyled.Wrapper} {
			height: 250px;
			background: ${({ selected }) => (selected ? color('primary') : rgbaColor('primary', 0.5))};
			color: ${({ selected }) => (selected ? color(['primary', '900']) : color('primary'))};
		}

		svg {
			margin: 0 !important;
			height: 100px;
		}

		${({ selected }) =>
			selected &&
			css`
				border-color: ${color('primary')};
				box-shadow: 0 6px 12px 0 ${rgbaColor(['primary', '900'], 0.25)};
			`}
	}

	${({ $smallSize }) =>
		$smallSize &&
		css`
			.ant-card-body {
				border-width: 2px;
			}
		`}
`

const OrderTypeFooter = styled.div<{ $smallSize?: boolean }>`
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: ${color(['primary', '800'])};
	padding: ${padding('xxs')};
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;

	.ant-typography {
		margin: 0 5px;
	}

	${({ $smallSize }) =>
		$smallSize &&
		css`
			font-size: ${fontSize('sm')};
			padding: 4px;

			.ant-typography {
				margin: 0;
			}

			.ant-radio-inner {
				transform: scale(0.75);
			}
		`}
`

const SkeletonCard = styled(Card)`
	.ant-card-body {
		padding: 0;
	}

	.ant-skeleton {
		width: 100%;
		height: 100%;

		.ant-skeleton-image {
			width: 100%;
			min-height: 178px;
		}
	}
`

export default { SubmitButtonContainer, OrderType, SkeletonCard, OrderTypeFooter }
